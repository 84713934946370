import React from "react";

import logo from "../assets/icons/PB-logo.svg";

export const Navbar: React.FC = () => {
    return (
        <div className="container">
            <img src={logo} alt="Pasha Bank logo" height={50} />
        </div>
    );
};
